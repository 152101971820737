export const FLOD_LIMIT_LIST = [
    {value: 1, name: "Buy in 16 Stakes 1/2", tierValue: '1/2'},
    {value: 2, name: "Buy in 32 Stakes 2/4", tierValue: '2/4'},
    {value: 3, name: "Buy in 80 Stakes 5/10", tierValue: '5/10'},
    {value: 4, name: "Buy in 160 Stakes 10/20", tierValue: '10/20'},
    {value: 5, name: "Buy in 400 Stakes 25/50", tierValue: '25/50'},
    {value: 6, name: "Buy in 800 Stakes 50/100", tierValue: '50/100'},
    {value: 7, name: "Buy in 1600 Stakes 100/200", tierValue: '100/200'},
    {value: 8, name: "Buy in 5000 Stakes 400/800", tierValue: '400/800'},
]

export const HOLDEM_LIMIT_LIST = [
    {value: 1, name: "Buy in 100 - 400 Stakes 1/2", tierValue: '1/2'},
    {value: 2, name: "Buy in 200 - 800 Stakes 2/4", tierValue: '2/4'},
    {value: 3, name: "Buy in 500 - 2,000 Stakes 5/10", tierValue: '5/10'},
    {value: 4, name: "Buy in 1,000 - 4,000 Stakes 10/20", tierValue: '10/20'},
    {value: 5, name: "Buy in 2,500 - 10,000 Stakes 25/50", tierValue: '25/50'},
    {value: 6, name: "Buy in 5,000 - 2,0000 Stakes 50/100", tierValue: '50/100'},
    {value: 7, name: "Buy in 10,000 - 40,000 Stakes 100/200", tierValue: '100/200'},
]

export const SIXPLUS_HOLDEMLIMIT_LIST = [
    {value: 1, name: "Buy in 50 - 200 Ante 1", tierValue: '1'},
    {value: 2, name: "Buy in 100 - 400 Ante 2", tierValue: '2'},
    {value: 3, name: "Buy in 200 - 800 Ante 4", tierValue: '4'},
    {value: 4, name: "Buy in 250 - 1,000 Ante 5", tierValue: '5'},
    {value: 5, name: "Buy in 500 - 2,000 Ante 10", tierValue: '10'},
    {value: 6, name: "Buy in 1,000 - 4,000 Ante 20", tierValue: '20'},
    {value: 7, name: "Buy in 2,500 - 10,000 Ante 50", tierValue: '50'},
    {value: 8, name: "Buy in 5,000 - 20,000 Ante 100", tierValue: '100'},
]

export const PLO4_LIMIT_LIST = [
    {value: 1, name: "Buy in 100 - 400 Stakes 1/2", tierValue: '1/2'},
    {value: 2, name: "Buy in 200 - 800 Stakes 2/4", tierValue: '2/4'},
    {value: 3, name: "Buy in 500 - 2,000 Stakes 5/10", tierValue: '5/10'},
    {value: 4, name: "Buy in 1,000 - 4,000 Stakes 10/20", tierValue: '10/20'},
    {value: 5, name: "Buy in 2,500 - 10,000 Stakes 25/50", tierValue: '25/50'},
    {value: 6, name: "Buy in 5,000 - 2,0000 Stakes 50/100", tierValue: '50/100'},
    {value: 7, name: "Buy in 10,000 - 40,000 Stakes 100/200", tierValue: '100/200'}
]

export const PLO5_LIMIT_LIST = [
    {value: 1, name: "Buy in 100 - 400 Stakes 1/2", tierValue: '1/2'},
    {value: 2, name: "Buy in 200 - 800 Stakes 2/4", tierValue: '2/4'},
    {value: 3, name: "Buy in 500 - 2,000 Stakes 5/10", tierValue: '5/10'},
    {value: 4, name: "Buy in 1,000 - 4,000 Stakes 10/20", tierValue: '10/20'},
    {value: 5, name: "Buy in 2,500 - 10,000 Stakes 25/50", tierValue: '25/50'},
    {value: 6, name: "Buy in 5,000 - 2,0000 Stakes 50/100", tierValue: '50/100'},
    {value: 7, name: "Buy in 10,000 - 40,000 Stakes 100/200", tierValue: '100/200'}
]